import React, { useState, useCallback, useRef } from 'react';
import { TikTokUserProfile, TikTokInfluencerResults } from '../interfaces/user';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, RefreshCw, Heart, Video, UserCheck, UserPlus } from 'lucide-react';
import { useApi } from '../apiUtils';

const formatNumber = (num: number): string => {
  if (num >= 1000000000) return (num / 1000000000).toFixed(1) + 'B';
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
  return num.toString();
};

const UserCard: React.FC<{ user: TikTokUserProfile }> = ({ user }) => (
  <motion.div
    layout
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-700 to-gray-800 p-4 sm:p-6 rounded-xl shadow-lg"
  >
    <div className="flex flex-col sm:flex-row items-center mb-4">
      <img src={user.profile_pic} alt={user.username} className="w-16 h-16 rounded-full mb-2 sm:mb-0 sm:mr-4 border-2 border-pink-500" />
      <div className="text-center sm:text-left overflow-hidden">
        <h4 className="font-bold text-lg sm:text-xl text-white truncate">{user.username}</h4>
        <p className="text-gray-300 text-xs sm:text-sm line-clamp-2">{user.bio}</p>
      </div>
    </div>
    <div className="grid grid-cols-3 gap-2 sm:gap-4 text-center">
      <div className="bg-gray-900 p-2 rounded-lg">
        <UserPlus className="w-4 h-4 sm:w-6 sm:h-6 text-blue-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Followers</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.followers.toLocaleString()}>{formatNumber(user.followers)}</p>
      </div>
      <div className="bg-gray-900 p-2 rounded-lg">
        <Heart className="w-4 h-4 sm:w-6 sm:h-6 text-red-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Likes</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.likes.toLocaleString()}>{formatNumber(user.likes)}</p>
      </div>
      <div className="bg-gray-900 p-2 rounded-lg">
        <Video className="w-4 h-4 sm:w-6 sm:h-6 text-green-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Posts</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.posts.toLocaleString()}>{formatNumber(user.posts)}</p>
      </div>
    </div>
  </motion.div>
);

const InfluencerFinder: React.FC = () => {
  const [influencers, setInfluencers] = useState<TikTokUserProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [influencerCount, setInfluencerCount] = useState(10);
  const callApi = useApi();
  const initialFetchDone = useRef(false);

  const fetchInfluencers = useCallback(async (count: number) => {
    setLoading(true);
    setError(null);
    try {
      const data: TikTokInfluencerResults = await callApi('/user/get-influencers', {
        params: { count: count.toString() }
      });
      setInfluencers(data.users);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [callApi]);

  React.useEffect(() => {
    if (!initialFetchDone.current) {
      fetchInfluencers(influencerCount);
      initialFetchDone.current = true;
    }
  }, []);

  const handleRefresh = () => {
    fetchInfluencers(influencerCount);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-4 sm:p-6 lg:p-8 xl:p-10 2xl:p-12 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl max-w-7xl mx-auto"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 sm:mb-6 lg:mb-8 text-white flex items-center justify-center"
      >
        <Users className="mr-2 text-pink-500" /> Top TikTok Influencers
      </motion.h2>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mb-4 sm:mb-6 lg:mb-8 flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4"
      >
        <div className="flex items-center space-x-2">
          <label htmlFor="influencerCount" className="text-white text-sm sm:text-base lg:text-lg">Number of influencers:</label>
          <input
            type="number"
            id="influencerCount"
            value={influencerCount}
            onChange={(e) => setInfluencerCount(Math.max(1, Math.min(100, parseInt(e.target.value) || 1)))}
            className="bg-gray-700 p-2 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-pink-500 transition duration-300 w-16 sm:w-20 lg:w-24"
            min="1"
            max="100"
          />
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRefresh}
          disabled={loading}
          className="w-full sm:w-auto bg-gradient-to-r from-pink-500 to-purple-600 text-white px-4 sm:px-6 lg:px-8 py-2 lg:py-3 rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 disabled:opacity-50 flex items-center justify-center"
        >
          {loading ? <RefreshCw className="animate-spin mr-2" /> : <RefreshCw className="mr-2" />}
          {loading ? 'Refreshing...' : 'Refresh Influencers'}
        </motion.button>
      </motion.div>

      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-red-500 mb-4 text-center bg-red-100 border border-red-400 rounded-lg p-3"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>

      {loading && influencers.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex justify-center items-center h-64"
        >
          <RefreshCw className="animate-spin text-pink-500 w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16" />
        </motion.div>
      )}

      <motion.div
        layout
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 sm:gap-6 lg:gap-8"
      >
        <AnimatePresence>
          {influencers.map((user) => (
            <UserCard key={user.username} user={user} />
          ))}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default InfluencerFinder;