import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchWithAuth from './fetchWithAuth';

interface User {
  username: string;
  is_admin: boolean;
  expiration: string | null;
}

interface AuthContextType {
  token: string | null;
  user: User | null;
  login: (token: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  const fetchUserInfo = useCallback(async () => {
    try {
      const userData = await fetchWithAuth('/user/me');
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user info:', error);
      logout();
    }
  }, [logout]);

  const login = useCallback(async (newToken: string) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    await fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token && user?.is_admin === false && user?.expiration) {
        const expirationTime = new Date(user.expiration).getTime();
        if (expirationTime < Date.now()) {
          logout();
        }
      }
    };

    if (token && !user) {
      fetchUserInfo();
    }

    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [token, user, fetchUserInfo, logout]);

  const contextValue = {
    token,
    user,
    login,
    logout,
    isAuthenticated: !!token
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};