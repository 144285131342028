import React, { useCallback, useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Search, TrendingUp, Users, User, Video, Brain, BarChart2, Zap, PenTool, Menu, X } from 'lucide-react';
import Particles from "react-particles";
import type { Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";

interface LandingPageProps {
  onTryForFree: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ onTryForFree }) => {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, 100]);
  const y2 = useTransform(scrollY, [0, 300], [0, -100]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  // Ref for the container of mid-sections
  const sectionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100');
          entry.target.classList.remove('opacity-0');
        } else {
          entry.target.classList.add('opacity-0');
          entry.target.classList.remove('opacity-100');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    if (sectionsRef.current) {
      const sections = sectionsRef.current.children;
      Array.from(sections).forEach((section) => {
        observer.observe(section);
      });
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-950 to-black text-white overflow-hidden">
      {/* Fixed Header */}
      <header className="fixed top-0 left-0 right-0 z-50 bg-opacity-70 bg-gradient-to-br from-transparent via-gray-900 backdrop-blur-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-2xl font-bold flex flex-row items-center">
            <div className="items-center flex">
              <img src={"/images/SwayedLogo.png"} className='h-10 w-10' alt="Swayed logo" />
            </div>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500  via-purple-700 text-2xl font-bold ml-4">
              Swayed
            </span>
          </div>
          <button
            onClick={onTryForFree}
            className="hidden md:block px-6 py-2 bg-gradient-to-r from-pink-500 to-violet-500 rounded-full font-semibold text-white transition duration-200 hover:shadow-lg"
          >
            Login
          </button>
          <button
            className="md:hidden text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-gray-900 py-4">
            <nav className="flex flex-col items-center space-y-4">
              <a href="#" className="text-gray-300 hover:text-white transition duration-200">Home</a>
              <a href="#" className="text-gray-300 hover:text-white transition duration-200">Features</a>
              <a href="#" className="text-gray-300 hover:text-white transition duration-200">Pricing</a>
              <a href="#" className="text-gray-300 hover:text-white transition duration-200">Contact</a>
              <button
                onClick={onTryForFree}
                className="px-6 py-2 bg-gradient-to-r from-pink-500 to-violet-500 rounded-full font-semibold text-white transition duration-200 hover:shadow-lg"
              >
                Try for Free
              </button>
            </nav>
          </div>
        )}
      </header>

      {/* Particles background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: { color: { value: "transparent" } },
          fpsLimit: 60,
          interactivity: {
            events: { onHover: { enable: true, mode: "repulse" }, resize: true },
            modes: { repulse: { distance: 100, duration: 0.4 } },
          },
          particles: {
            color: { value: "#ffffff" },
            links: { color: "#ffffff", distance: 150, enable: true, opacity: 0.3, width: 1 },
            move: { direction: "none", enable: true, outModes: { default: "bounce" }, random: false, speed: 1, straight: false },
            number: { density: { enable: true, area: 800 }, value: 80 },
            opacity: { value: 0.5 },
            shape: { type: "circle" },
            size: { value: { min: 1, max: 3 } },
          },
          detectRetina: true,
        }}
      />

      <main className="relative z-10">
        {/* Initial view content */}
        <div className="mt-[80px] flex flex-col justify-center items-center px-4">
          <motion.h2
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-5xl md:text-8xl font-bold text-center mb-8 leading-tight"
          >
            Unleash the Power of{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-violet-500 animate-pulse">
              TikTok
            </span>{' '}
            with AI
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="text-2xl md:text-3xl text-center mb-20 max-w-3xl mx-auto text-gray-300"
          >
            Discover trends, analyze influencers, and create AI-powered content tailored to your style.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="flex justify-center lg:mt-20"
          >
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-pink-600 to-purple-600 rounded-full blur-xl opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <button
                onClick={onTryForFree}
                className="relative px-10 py-5 md:px-16 md:py-8 bg-gradient-to-r from-pink-500 to-indigo-500  via-purple-700 rounded-full font-bold text-2xl md:text-3xl text-white transition duration-200 shadow-xl hover:shadow-2xl"
              >
                Try for Free
              </button>
            </div>
          </motion.div>
        </div>

        {/* Mid-sections with fade effect */}
        <div ref={sectionsRef} className="md:space-y-32 container mx-auto px-4 mt-56">
          <div className='flex items-center justify-center text-center'>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500  via-purple-700 animate-pulse text-4xl font-bold">
              Elevate your Tiktok game
            </span>
          </div>

          {[
            {
              icon: BarChart2,
              title: "Personalized Content Analysis",
              description: "Connect your TikTok handle to Swayed, and let our AI analyze your videos. We store your unique style, mood, and delivery metrics, ensuring every piece of content aligns with your brand.",
              imageSrc: "/images/finalInfographicSection1.png",
              imageAlt: "Graph showing personalized content analysis"
            },
            {
              icon: Zap,
              title: "Trend-Based Content Creation",
              description: "Stay ahead of the curve with Swayed's trend analysis. Our platform identifies the hottest TikTok videos and provides you with ideas to create your own content in your distinct style.",
              imageSrc: "/images/section2Summary.png",
              imageAlt: "Visualization of trend-based content creation"
            },
            {
              icon: PenTool,
              title: "AI-Powered Script Generation",
              description: "Simplify your content creation process with Swayed's AI-generated scripts. Based on your personal style and trending content, we provide tailored video scripts that help you produce high-quality videos effortlessly.",
              imageSrc: "/images/section3Scripts.png",
              imageAlt: "Illustration of AI-powered script generation"
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-center items-center rounded-3xl gap-8 bg-gradient-to-t md:bg-gradient-to-br from-transparent via-gray-900 py-20 px-5 opacity-0 transition-opacity duration-1000"
            >
              <div className={`w-full md:w-[40%] mb-8 md:mb-0 ${index % 2 === 0 ? 'md:mr-8' : 'md:order-2 md:ml-8'}`}>
                <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl overflow-hidden transition duration-300 shadow-2xl hover:shadow-xl group h-full flex flex-col justify-center items-center">
                  <img src={feature.imageSrc} alt={feature.imageAlt} className="w-full h-auto rounded-lg" />
                </div>
              </div>
              <div className={`w-full md:w-1/2 ${index % 2 === 0 ? '' : 'md:order-1'}`}>
                <h3 className="text-3xl font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                  {feature.title}
                </h3>
                <p className="text-gray-300 text-xl">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="container mx-auto px-4 py-10 mt-32">
          <div className='flex items-center justify-center text-center pb-10'>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500  via-purple-700 animate-pulse text-4xl font-bold">
              Packed with powerful features
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { icon: Search, title: "Advanced Search", description: "Find TikTok videos by query, date, and country." },
              { icon: TrendingUp, title: "Trending Videos", description: "Discover the hottest TikTok content right now." },
              { icon: Users, title: "Top Influencers", description: "Analyze profiles of leading content creators." },
              { icon: User, title: "Profile Search", description: "Look up any TikTok profile instantly." },
              { icon: Brain, title: "AI Learning", description: "Train AI on your Instagram or TikTok style." },
              { icon: Video, title: "AI Content Generation", description: "Create personalized video scripts with AI." },
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 * (index + 1) }}
                className="bg-gradient-to-br from-transparent via-gray-900 rounded-3xl p-8 backdrop-blur-lg transition duration-300 hover:shadow-2xl group"
              >
                <feature.icon className="w-16 h-16 text-pink-500 mb-6 transition-transform duration-300 group-hover:scale-110" />
                <h3 className="text-2xl font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 group-hover:from-pink-400 group-hover:to-violet-400 transition-all duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-300 text-lg transition-all duration-300 group-hover:text-white">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </main>

      <footer className="bg-gray-900 py-16 relative z-10">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div>
              <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">Swayed</h3>
              <p className="text-gray-400">Elevate your TikTok game with AI-powered insights and content creation.</p>
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-4 text-white">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition duration-200">Terms of Service</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition duration-200">Privacy Policy</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition duration-200">Cookie Policy</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-4 text-white">Connect</h4>
              <ul className="space-y-2">
                <li><a href="https://byteb.io" target="_blank" className="text-gray-400 hover:text-white transition duration-200">Website</a></li>
                <li><a href="https://linkedin.com/company/byteb" target="_blank" className="text-gray-400 hover:text-white transition duration-200">LinkedIn</a></li>
                <li><a href="https://twitter.com/WeAreBytebricks" target="_blank" className="text-gray-400 hover:text-white transition duration-200">Twitter</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy;
              <span className="font-extralight mb-8 text-transparent bg-clip-text bg-gradient-to-r from-slate-400 to-slate-50">
                2024 Swayed. All rights reserved.
              </span> 
              <br></br>
              <span className="font-extralight mb-8 text-transparent bg-clip-text bg-gradient-to-r from-slate-400 to-slate-50">
              Powered By
              </span>
              <span className='font-light bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-400'>{' '}Bytebricks</span>
              </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;