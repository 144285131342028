import React, { useState, useEffect } from 'react';
import { ProfileAnalysis, VideoAnalysis } from '../interfaces/user';
import { EyeIcon, HeartIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { useApi } from '../apiUtils';

const loadingTexts = [
  "Analyzing Instagram profile...",
  "Extracting video data...",
  "Processing content...",
  "Determining language patterns...",
  "Analyzing tone and style...",
  "Training AI on your unique style...",
  "Finalizing AI model...",
];

const VideoCard: React.FC<{ video: VideoAnalysis; getProxiedImageUrl: (url: string) => Promise<string> }> = ({ video, getProxiedImageUrl }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');

  useEffect(() => {
    if (video.thumbnail) {
      getProxiedImageUrl(video.thumbnail).then(setThumbnailUrl);
    }
  }, [video.thumbnail, getProxiedImageUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white bg-opacity-10 rounded-2xl overflow-hidden shadow-2xl backdrop-blur-lg hover:shadow-pink-500/20 transition-all duration-300 mb-8"
    >
      <div className="p-8">
        <div className="flex items-center mb-6">
          {thumbnailUrl && (
            <img
              src={thumbnailUrl}
              alt={video.title || 'Video thumbnail'}
              className="w-32 h-32 rounded-xl object-cover mr-6 shadow-lg"
            />
          )}
          <div>
            <h4 className="text-2xl font-bold text-white mb-3">{video.title || 'Untitled Video'}</h4>
            <p className="text-gray-300">{video.caption || 'No caption'}</p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 mb-8">
          <div className="flex items-center text-gray-300">
            <EyeIcon className="h-6 w-6 mr-3 text-pink-400" />
            <span>{video.viewcount || 'N/A'} views</span>
          </div>
          <div className="flex items-center text-gray-300">
            <HeartIcon className="h-6 w-6 mr-3 text-pink-400" />
            <span>{video.likecount || 'N/A'} likes</span>
          </div>
          <div className="flex items-center text-gray-300">
            <CalendarIcon className="h-6 w-6 mr-3 text-pink-400" />
            <span>{video.posted_on_utc ? new Date(video.posted_on_utc).toLocaleDateString() : 'N/A'}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const InstagramProfileProcessor: React.FC = () => {
  const [username, setUsername] = useState('');
  const [profileData, setProfileData] = useState<ProfileAnalysis | null>(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loadingText, setLoadingText] = useState(loadingTexts[0]);
  const [error, setError] = useState<string | null>(null);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const callApi = useApi();
  
  useEffect(() => {
    if (!initialFetchDone) {
      fetchProfile();
    }
  }, [initialFetchDone]);

  useEffect(() => {
    if (processing) {
      const interval = setInterval(() => {
        setLoadingText(prevText => {
          const currentIndex = loadingTexts.indexOf(prevText);
          return loadingTexts[(currentIndex + 1) % loadingTexts.length];
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [processing]);

  const processProfile = async () => {
    setProcessing(true);
    setError(null);
    setProfileData(null);
    try {
      const data: ProfileAnalysis = await callApi('/user/process-my-instagram-profile', {
        params: { insta_userid: username },
      });
      setProfileData(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setProcessing(false);
    }
  };

  const fetchProfile = async () => {
    setLoading(true);
    setError(null);
    try {
      const data: ProfileAnalysis = await callApi('/user/get-my-instagram-profile');
      setProfileData(data);
    } catch (err) {
      if (err instanceof Error && err.message.includes('404')) {
        setError('No profile processed yet. Please process a profile first.');
      } else {
        setError(err instanceof Error ? err.message : 'An error occurred');
      }
    } finally {
      setLoading(false);
      setInitialFetchDone(true);
    }
  };

  const getProxiedImageUrl = async (originalUrl: string): Promise<string> => {
    try {
      const response = await callApi('/user/proxy-image', {
        params: { url: originalUrl },
      },
      false);
      // Since the API returns a StreamingResponse, we need to handle it differently
      return URL.createObjectURL(response);
    } catch (error) {
      console.error('Error fetching proxied image:', error);
      return ''; // Return an empty string or a placeholder image URL
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="max-w-4xl mx-auto"
      >
        <h2 className="text-5xl font-extrabold mb-16 pt-8 pb-4 px-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-600 text-center">
          Instagram AI Trainer
        </h2>
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="mb-12 bg-white bg-opacity-10 p-8 rounded-2xl shadow-2xl backdrop-blur-lg"
        >
          <input
            type="text"
            placeholder="Enter Instagram username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full bg-white bg-opacity-20 p-4 rounded-lg mb-6 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 transition duration-300"
          />
          <motion.button
            onClick={processProfile}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white px-8 py-4 rounded-lg font-bold text-lg hover:from-pink-600 hover:to-purple-700 transition-all duration-300 shadow-lg"
            disabled={processing}
          >
            {processing ? 'Processing...' : 'Analyze and Train AI'}
          </motion.button>
        </motion.div>
        {(loading || processing) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex flex-col items-center justify-center space-y-6"
          >
            <div className="w-24 h-24 border-t-4 border-pink-500 border-solid rounded-full animate-spin"></div>
            <p className="text-white text-xl font-medium">{loadingText}</p>
          </motion.div>
        )}
        {error && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-red-400 mb-8 text-center text-lg"
          >
            {error}
          </motion.p>
        )}
        {profileData && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="space-y-12"
          >
            <h3 className="text-4xl font-bold mb-8 text-white text-center">
              {`Profile Analysis of ${profileData.account_id}`}
            </h3>
            <div className="grid grid-cols-2 gap-6 mb-12">
              <motion.div whileHover={{ scale: 1.05 }} className="bg-gradient-to-br from-pink-500/20 to-purple-600/20 p-6 rounded-xl">
                <h4 className="text-xl font-bold text-white mb-3">Language</h4>
                <p className="text-lg text-gray-300">{profileData.analysis.language || 'N/A'}</p>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} className="bg-gradient-to-br from-yellow-500/20 to-orange-600/20 p-6 rounded-xl">
                <h4 className="text-xl font-bold text-white mb-3">Tone</h4>
                <p className="text-lg text-gray-300">{profileData.analysis.tone || 'N/A'}</p>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} className="bg-gradient-to-br from-blue-500/20 to-indigo-600/20 p-6 rounded-xl">
                <h4 className="text-xl font-bold text-white mb-3">Style</h4>
                <p className="text-lg text-gray-300">{profileData.analysis.style || 'N/A'}</p>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} className="bg-gradient-to-br from-green-500/20 to-teal-600/20 p-6 rounded-xl">
                <h4 className="text-xl font-bold text-white mb-3">Vocabulary</h4>
                <p className="text-lg text-gray-300">{profileData.analysis.vocabulary || 'N/A'}</p>
              </motion.div>
            </div>
            <h4 className="text-3xl font-bold mb-8 text-white">
              Analyzed Videos
            </h4>
            {profileData.content.map((video, index) => (
              <VideoCard key={index} video={video} getProxiedImageUrl={getProxiedImageUrl} />
            ))}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default InstagramProfileProcessor;