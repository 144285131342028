import React, { useState, useRef, useEffect } from 'react';
import { PlayIcon, PauseIcon, SpeakerWaveIcon, SpeakerXMarkIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { useApi } from '../apiUtils';

interface CustomVideoPlayerProps {
  videoUrl: string;
  videoHeaders: string;
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({ videoUrl, videoHeaders }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const loadingRef = useRef<boolean>(true);
  const playPromiseRef = useRef<Promise<void> | null>(null);
  const blobUrlRef = useRef<string | null>(null);
  const callApi = useApi();

  useEffect(() => {
    loadVideo();
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.removeAttribute('src');
        videoRef.current.load();
      }
    };
  }, [videoUrl, videoHeaders]);

  const loadVideo = async () => {
    if (!videoRef.current) return;

    try {
      setIsLoading(true);
      setError(null);
      loadingRef.current = true;

      const blob = await callApi('/video/stream', {
        params: {
          tiktok_url: videoUrl,
          headers: videoHeaders
        },
      }, false);

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      videoRef.current.src = blobUrlRef.current;

      await new Promise((resolve, reject) => {
        if (!videoRef.current) {
          reject(new Error('Video element not found'));
          return;
        }

        const handleCanPlay = () => {
          videoRef.current?.removeEventListener('canplay', handleCanPlay);
          videoRef.current?.removeEventListener('error', handleError);
          resolve(null);
        };

        const handleError = (e: Event) => {
          videoRef.current?.removeEventListener('canplay', handleCanPlay);
          videoRef.current?.removeEventListener('error', handleError);
          reject(e);
        };

        videoRef.current.addEventListener('canplay', handleCanPlay);
        videoRef.current.addEventListener('error', handleError);
      });

      setIsLoading(false);
      loadingRef.current = false;
    } catch (e) {
      console.error('Error loading video:', e);
      setError("Failed to load video. Please try again later.");
      setIsLoading(false);
      loadingRef.current = false;
    }
  };

  const playVideo = async () => {
    if (!videoRef.current || loadingRef.current) return;

    try {
      playPromiseRef.current = videoRef.current.play();
      await playPromiseRef.current;
      setIsPlaying(true);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        console.log('Play request aborted');
      } else {
        console.error('Error playing video:', e);
        setError("Failed to play video. Please try again later.");
      }
    } finally {
      playPromiseRef.current = null;
    }
  };

  const pauseVideo = () => {
    if (!videoRef.current || loadingRef.current) return;

    if (playPromiseRef.current) {
      playPromiseRef.current.then(() => {
        videoRef.current?.pause();
        setIsPlaying(false);
      }).catch(() => {
        // Ignore any errors from the previous play promise
      });
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const replayVideo = () => {
    if (!videoRef.current || loadingRef.current) return;

    videoRef.current.currentTime = 0;
    playVideo();
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleWaiting = () => setIsBuffering(true);
    const handleCanPlay = () => setIsBuffering(false);
    const handleError = (e: Event) => {
      console.error('Video error:', e);
      setError('An error occurred while playing the video.');
      setIsBuffering(false);
    };

    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('error', handleError);

    return () => {
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <div className="relative w-full h-0 pb-[177.78%] overflow-hidden rounded-lg bg-gray-900">
      {(isLoading || isBuffering) && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="animate-spin rounded-full h-8 w-8 sm:h-12 sm:w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      {error ? (
        <div className="absolute inset-0 flex items-center justify-center text-white text-center p-4">
          <p className="text-sm sm:text-base">{error}</p>
        </div>
      ) : (
        <>
          <video
            ref={videoRef}
            className="absolute top-0 left-0 w-full h-full object-cover"
            loop
            playsInline
            muted={isMuted}
            onClick={togglePlay}
          />
          <div className="absolute bottom-0 left-0 right-0 p-2 sm:p-4 bg-gradient-to-t from-black to-transparent">
            <div className="flex justify-center space-x-2 sm:space-x-4">
              <button
                onClick={togglePlay}
                className="text-white p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 transition-colors"
              >
                {isPlaying ? (
                  <PauseIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                ) : (
                  <PlayIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                )}
              </button>
              <button
                onClick={replayVideo}
                className="text-white p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 transition-colors"
              >
                <ArrowPathIcon className="w-6 h-6 sm:w-8 sm:h-8" />
              </button>
              <button
                onClick={toggleMute}
                className="text-white p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 transition-colors"
              >
                {isMuted ? (
                  <SpeakerXMarkIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                ) : (
                  <SpeakerWaveIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomVideoPlayer;