import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, User, Video, CreditCard, Instagram, Settings, LogOut, Shield, Menu, X } from 'lucide-react';
import { useAuth } from '../AuthContext';
import ViralVideoFinder from './ViralVideoFinder';
import VideoSearch from './VideoSearch';
import PricingSubscription from './PricingSubscription';
import UserSearch from './TikTokUserSearch';
import InfluencerFinder from './InfluencerFinder';
import InstagramProfileProcessor from './InstagramProfileProcessor';
import AdminPanel from './AdminPanel';

const Dashboard: React.FC = () => {
  const [activeScreen, setActiveScreen] = useState('userSearch');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { logout, user } = useAuth();

  const renderActiveScreen = () => {
    switch (activeScreen) {
      case 'userSearch':
        return <UserSearch />;
      case 'influencerFinder':
        return <InfluencerFinder />;
      case 'viralVideoFinder':
        return <ViralVideoFinder />;
      case 'videoSearch':
        return <VideoSearch />;
      case 'pricingSubscription':
        return <PricingSubscription />;
      case 'instagramProfile':
        return <InstagramProfileProcessor />;
      case 'adminPanel':
        return user?.is_admin ? <AdminPanel /> : <div>Access Denied</div>;
      default:
        return <UserSearch />;
    }
  };

  const NavButton: React.FC<{ screen: string; icon: React.ReactNode; label: string }> = ({ screen, icon, label }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        setActiveScreen(screen);
        setIsMobileMenuOpen(false);
      }}
      className={`flex items-center w-full text-left p-3 rounded-lg transition duration-300 ${
        activeScreen === screen ? 'bg-gradient-to-r from-pink-500 to-purple-600 text-white' : 'text-gray-300 hover:bg-gray-700'
      }`}
    >
      {React.cloneElement(icon as React.ReactElement, { className: "mr-3", size: 20 })}
      {label}
    </motion.button>
  );

  const Sidebar = () => (
    <motion.div
      initial={{ x: -300 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
      className={`w-72 bg-gray-800 shadow-xl flex flex-col fixed h-full overflow-y-auto z-20 ${
        isMobileMenuOpen ? 'block' : 'hidden'
      } lg:block`}
    >
      <div className="p-6 flex-grow">
        <div className="flex justify-between items-center mb-0">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600"
          >
            Swayed
          </motion.h1>
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className="lg:hidden text-white focus:outline-none"
          >
            <X size={24} />
          </button>
        </div>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="font-extralight mb-8 text-transparent bg-clip-text bg-gradient-to-r from-slate-400 to-slate-50"
        >
          Powered By <span className='font-light bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-400'>Bytebricks</span>
        </motion.h2>
        {user && (
          <div className="mb-6 text-white font-semibold">
            <p>Welcome! {user.username}</p>
          </div>
        )}
        <nav className="flex-grow">
          <h2 className="text-sm uppercase text-gray-500 mb-4">TikTok Tools</h2>
          <motion.ul className="space-y-3 mb-8">
            <NavButton screen="userSearch" icon={<User />} label="User Search" />
            <NavButton screen="influencerFinder" icon={<User />} label="Influencer Finder" />
            <NavButton screen="viralVideoFinder" icon={<Video />} label="Viral Video Finder" />
            <NavButton screen="videoSearch" icon={<Search />} label="Video Search" />
          </motion.ul>
          
          <h2 className="text-sm uppercase text-gray-500 mb-4">AI Training Tools</h2>
          <motion.ul className="space-y-3">
            <NavButton screen="instagramProfile" icon={<Instagram />} label="Instagram AI Trainer" />
          </motion.ul>

          {user?.is_admin && (
            <>
              <h2 className="text-sm uppercase text-gray-500 mb-4 mt-8">Admin</h2>
              <motion.ul className="space-y-3">
                <NavButton screen="adminPanel" icon={<Shield />} label="Admin Panel" />
              </motion.ul>
            </>
          )}
        </nav>
      </div>
      <div className="p-6 bg-gray-800">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={logout}
          className="flex items-center w-full text-left p-3 rounded-lg transition duration-300 text-gray-300 hover:bg-gray-700"
        >
          <LogOut className="mr-3" size={20} />
          Logout
        </motion.button>
      </div>
    </motion.div>
  );

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <Sidebar />
      <div className="flex-1 lg:ml-72">
        <div className="p-4 lg:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            <Menu size={24} />
          </button>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="p-8"
        >
          {renderActiveScreen()}
        </motion.div>
      </div>
    </div>
  );
};

export default Dashboard;