import React, { useState } from 'react';
import { TikTokVideoSearchResults } from '../interfaces/video';
import VideoCard from './VideoCard';
import { Search, Globe, Calendar } from 'lucide-react';
import { motion } from 'framer-motion';
import { useApi } from '../apiUtils';

const VideoSearch: React.FC = () => {
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState('us');
  const [timeRange, setTimeRange] = useState(7);
  const [timeUnit, setTimeUnit] = useState<'days' | 'weeks' | 'months'>('days');
  const [searchResults, setSearchResults] = useState<TikTokVideoSearchResults | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const callApi = useApi();

  const getMaxRange = () => {
    switch (timeUnit) {
      case 'days': return 90;
      case 'weeks': return 12;
      case 'months': return 3;
    }
  };

  const getDays = () => {
    switch (timeUnit) {
      case 'days': return timeRange;
      case 'weeks': return timeRange * 7;
      case 'months': return timeRange * 30;
    }
  };

  const searchVideos = async (cursor?: string) => {
    setLoading(true);
    setError(null);
    try {
      const params: Record<string, string> = {
        keyword,
        country,
        days: getDays().toString(),
      };
      if (cursor) {
        params.cursor = cursor;
      }
      
      const data: TikTokVideoSearchResults = await callApi('/video/search', { params });
      setSearchResults(prevResults => 
        cursor && prevResults 
          ? { ...data, videos: [...prevResults.videos, ...data.videos] }
          : data
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (searchResults && searchResults.has_more) {
      searchVideos(searchResults.cursor);
    }
  };

  const showLoadMoreButton = searchResults?.has_more && searchResults.videos.length > 0;

  return (
    <div className="p-8 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-3xl font-bold mb-6 text-white text-center"
      >
        TikTok Video Search
      </motion.h2>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-6 space-y-6"
      >
        <div className="space-y-2">
          <label htmlFor="keyword" className="block text-sm font-medium text-gray-300">Search Keyword</label>
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              id="keyword"
              type="text"
              placeholder="Enter topic, hashtag, or keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="w-full bg-gray-700 p-3 pl-10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition duration-300"
            />
          </div>
        </div>
        
        <div className="space-y-2">
          <label htmlFor="country" className="block text-sm font-medium text-gray-300">Country</label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              id="country"
              type="text"
              placeholder="Enter country code (e.g., us, ca, gb)"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full bg-gray-700 p-3 pl-10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition duration-300"
            />
          </div>
          <p className="text-xs text-gray-400 mt-1">Use 2-letter country codes (ISO 3166-1 alpha-2)</p>
        </div>
        
        <div className="space-y-2">
          <label htmlFor="timeRange" className="block text-sm font-medium text-gray-300">Time Range</label>
          <div className="flex items-center space-x-4">
            <Calendar className="text-gray-400" />
            <input
              id="timeRange"
              type="range"
              min="1"
              max={getMaxRange()}
              value={timeRange}
              onChange={(e) => setTimeRange(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-pink-500"
            />
            <span className="text-white font-medium whitespace-nowrap">{timeRange} {timeUnit}</span>
          </div>
          <div className="flex justify-between mt-2">
            {(['days', 'weeks', 'months'] as const).map((unit) => (
              <button
                key={unit}
                onClick={() => {
                  setTimeUnit(unit);
                  setTimeRange(1); // Reset to 1 when changing units
                }}
                className={`px-3 py-1 rounded-full text-sm ${
                  timeUnit === unit
                    ? 'bg-pink-500 text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                } transition duration-300`}
              >
                {unit.charAt(0).toUpperCase() + unit.slice(1)}
              </button>
            ))}
          </div>
          <p className="text-xs text-gray-400 mt-1">Select time range for video search (up to 90 days, 12 weeks, or 3 months)</p>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => searchVideos()}
          className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white px-6 py-3 rounded-lg font-semibold shadow-lg hover:shadow-xl transition duration-300"
          disabled={loading}
        >
          {loading ? 'Searching...' : 'Search Videos'}
        </motion.button>
      </motion.div>
      {error && (
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-red-500 mb-4 text-center"
        >
          {error}
        </motion.p>
      )}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        {searchResults?.videos.map((video, index) => (
          <motion.div
            key={`${video.video_id}-${index}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <VideoCard video={video} />
          </motion.div>
        ))}
      </motion.div>
      {loading && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-6 text-center"
        >
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-pink-500"></div>
        </motion.div>
      )}
      {showLoadMoreButton && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-6 text-center"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={loadMore}
            className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold shadow-lg hover:shadow-xl transition duration-300"
            disabled={loading}
          >
            Load More
          </motion.button>
        </motion.div>
      )}
      {searchResults && searchResults.videos.length === 0 && !loading && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-white text-center mt-6"
        >
          No videos found for the given search criteria.
        </motion.p>
      )}
    </div>
  );
};

export default VideoSearch;