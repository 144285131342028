import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { TikTokVideoIdea } from '../interfaces/video';

interface IdeaModalProps {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  error: string | null;
  idea: TikTokVideoIdea | null;
}

const IdeaModal: React.FC<IdeaModalProps> = ({ isOpen, onClose, loading, error, idea }) => {
  if (!isOpen) return null;

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] flex flex-col">
        <h2 className="text-4xl font-bold mb-4 text-white">Generated Idea</h2>
        
        <div className="flex-grow overflow-y-auto pr-2">
          {loading && (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}
          
          {error && <p className="text-red-500 mb-4">{error}</p>}
          
          {idea && (
            <div className="prose prose-invert prose-headings:text-blue-300 prose-a:text-blue-400 prose-strong:text-yellow-300 prose-em:text-green-300 max-w-none">
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                components={{
                  h1: ({node, ...props}) => <h1 className="text-2xl font-bold mt-4 mb-2" {...props} />,
                  h2: ({node, ...props}) => <h2 className="text-xl font-semibold mt-3 mb-2" {...props} />,
                  h3: ({node, ...props}) => <h3 className="text-lg font-medium mt-2 mb-1" {...props} />,
                  ul: ({node, ...props}) => <ul className="list-disc list-inside my-2" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal list-inside my-2" {...props} />,
                  li: ({node, ...props}) => <li className="my-1" {...props} />,
                  p: ({node, ...props}) => <p className="my-2" {...props} />,
                }}
              >
                {idea.idea}
              </ReactMarkdown>
            </div>
          )}
        </div>
        
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default IdeaModal;