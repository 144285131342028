import React, { useState, useCallback } from 'react';
import { TikTokVideo, TikTokTrendingVideos } from '../interfaces/video';
import VideoCard from './VideoCard';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp, Loader } from 'lucide-react';
import { useApi } from '../apiUtils';

const ViralVideoFinder: React.FC = () => {
  const [videos, setVideos] = useState<TikTokVideo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = useState(6);
  const callApi = useApi();

  const fetchTrendingVideos = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data: TikTokTrendingVideos = await callApi('/video/trending', {
        params: { count: count.toString() }
      });
      setVideos(data.videos);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [count, callApi]);

  const loadMoreVideos = async () => {
    setLoading(true);
    setError(null);
    try {
      const data: TikTokTrendingVideos = await callApi('/video/trending', {
        params: { count: '3' }
      });
      setVideos(prevVideos => [...prevVideos, ...data.videos]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-8 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-3xl font-bold mb-6 text-white flex items-center justify-center"
      >
        <TrendingUp className="mr-2 text-pink-500" /> Viral Video Finder
      </motion.h2>
      
      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-red-500 mb-4 text-center bg-red-100 border border-red-400 rounded-lg p-3"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mb-6 flex items-center justify-center space-x-4"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={fetchTrendingVideos}
          disabled={loading}
          className="bg-gradient-to-r from-pink-500 to-purple-600 text-white px-6 py-2 rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 disabled:opacity-50 flex items-center"
        >
          {loading ? <Loader className="animate-spin mr-2" /> : <TrendingUp className="mr-2" />}
          {loading ? 'Fetching...' : 'Fetch Viral Videos'}
        </motion.button>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        <AnimatePresence>
          {videos.map((video, index) => (
            <motion.div
              key={video.video_id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <VideoCard video={video} />
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
      
      {videos.length > 0 && (
        <div className="mt-6 flex justify-center">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={loadMoreVideos}
            disabled={loading}
            className="bg-blue-500 text-white px-6 py-2 rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 disabled:opacity-50 flex items-center"
          >
            {loading ? <Loader className="animate-spin mr-2" /> : null}
            {loading ? 'Loading...' : 'Load More'}
          </motion.button>
        </div>
      )}
    </motion.div>
  );
};

export default ViralVideoFinder;