import React from 'react';

const PricingSubscription: React.FC = () => {
  return (
    <div className="p-6 bg-gray-800 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Pricing & Subscription</h2>
      <p>Pricing and subscription details go here.</p>
    </div>
  );
};

export default PricingSubscription;