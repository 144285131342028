import React, { useState } from 'react';
import { TikTokVideo, TikTokVideoIdea } from '../interfaces/video';
import IdeaModal from './IdeaModal';
import CustomVideoPlayer from './CustomVideoPlayer';
import { useApi } from '../apiUtils';

interface VideoCardProps {
  video: TikTokVideo;
}

const VideoCard: React.FC<VideoCardProps> = ({ video }) => {
  const [idea, setIdea] = useState<TikTokVideoIdea | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const callApi = useApi();

  const generateIdea = async () => {
    setLoading(true);
    setError(null);
    setIsModalOpen(true);
    try {
      const data: TikTokVideoIdea = await callApi('/video/get-idea', {
        params: {
          tiktok_url: video.video_url,
          headers: video.video_link_headers
        }
      });
      setIdea(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden">
      <CustomVideoPlayer videoUrl={video.video_url} videoHeaders={video.video_link_headers} />
      <div className="p-4">
        <h3 className="font-bold text-lg mb-2">{video.username}</h3>
        <p className="text-sm text-gray-300 mb-1">Followers: {video.followers.toLocaleString()}</p>
        <p className="text-sm text-gray-300 mb-1">Likes: {video.likes.toLocaleString()}</p>
        <p className="text-sm text-gray-300 mb-1">Posts: {video.posts.toLocaleString()}</p>
        <p className="text-sm text-gray-300 mb-1">Avg Views: {video.avg_views.toLocaleString()}</p>
        <p className="text-sm text-gray-300 mb-1">Engagement Rate: {(video.engagement_rate * 100).toFixed(2)}%</p>
        <p className="text-sm text-gray-300 mb-2">Last Upload: {video.days_since_upload} days ago</p>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={generateIdea}
            className="bg-pink-500 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-pink-600 transition duration-300"
          >
            Get Idea
          </button>
        </div>
      </div>
      <IdeaModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        loading={loading}
        error={error}
        idea={idea}
      />
    </div>
  );
};

export default VideoCard;