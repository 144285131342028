import React, { useState } from 'react';
import { useApi } from '../apiUtils';
import { motion } from 'framer-motion';
import { User, Clock, Lock } from 'lucide-react';

interface TempUser {
  username: string;
  expiration: string;
}

type TimeUnit = 'minutes' | 'hours' | 'days' | 'weeks';

const AdminPanel: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [expiryValue, setExpiryValue] = useState(1);
  const [expiryUnit, setExpiryUnit] = useState<TimeUnit>('days');
  const [generatedUser, setGeneratedUser] = useState<TempUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const callApi = useApi();

  const calculateExpiryMinutes = (): number => {
    switch (expiryUnit) {
      case 'minutes':
        return expiryValue;
      case 'hours':
        return expiryValue * 60;
      case 'days':
        return expiryValue * 24 * 60;
      case 'weeks':
        return expiryValue * 7 * 24 * 60;
    }
  };

  const handleGenerateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setGeneratedUser(null);
    setLoading(true);

    try {
      const params: Record<string, any> = {
        username,
        password,
        expiry_minutes: calculateExpiryMinutes()
      };
      const response = await callApi('/user/create-temp-user', { params, method: 'POST' });

      setGeneratedUser(response);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const formatExpiryTime = (minutes: number): string => {
    const weeks = Math.floor(minutes / (7 * 24 * 60));
    const days = Math.floor((minutes % (7 * 24 * 60)) / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const mins = minutes % 60;

    const parts = [];
    if (weeks > 0) parts.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
    if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    if (mins > 0) parts.push(`${mins} minute${mins > 1 ? 's' : ''}`);

    return parts.join(', ');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gray-800 p-8 rounded-lg shadow-xl"
    >
      <h2 className="text-3xl font-bold mb-6 text-white">Generate Self-Destructing Account</h2>
      <form onSubmit={handleGenerateAccount} className="space-y-4">
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-300 mb-1">
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="expiryValue" className="block text-sm font-medium text-gray-300 mb-1">
              Expiry Time
            </label>
            <input
              type="number"
              id="expiryValue"
              value={expiryValue}
              onChange={(e) => setExpiryValue(parseInt(e.target.value))}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              min="1"
              required
            />
          </div>
          <div className="flex-1">
            <label htmlFor="expiryUnit" className="block text-sm font-medium text-gray-300 mb-1">
              Unit
            </label>
            <select
              id="expiryUnit"
              value={expiryUnit}
              onChange={(e) => setExpiryUnit(e.target.value as TimeUnit)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="minutes">Minutes</option>
              <option value="hours">Hours</option>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
            </select>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          disabled={loading}
        >
          {loading ? (
            <span className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></span>
          ) : (
            <>
              <User className="mr-2" size={20} />
              Generate Account
            </>
          )}
        </motion.button>
      </form>

      {error && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-4 text-red-500"
        >
          {error}
        </motion.p>
      )}

      {generatedUser && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-6 bg-gray-700 p-4 rounded-lg"
        >
          <h3 className="text-xl font-semibold mb-2 text-white">Generated Account</h3>
          <p className="text-gray-300 flex items-center">
            <User className="mr-2" size={16} />
            <strong>Username:</strong> {generatedUser.username}
          </p>
          <p className="text-gray-300 flex items-center">
            <Clock className="mr-2" size={16} />
            <strong>Expires at:</strong> {new Date(generatedUser.expiration).toLocaleString()}
          </p>
          <p className="text-gray-300 flex items-center">
            <Clock className="mr-2" size={16} />
            <strong>Account duration:</strong> {formatExpiryTime(calculateExpiryMinutes())}
          </p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default AdminPanel;