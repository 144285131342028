import React, { useState, useCallback } from 'react';
import { TikTokUserProfile, TikTokUserSearchResults } from '../interfaces/user';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, UserPlus, Heart, Video, Loader } from 'lucide-react';
import { useApi } from '../apiUtils';

const formatNumber = (num: number): string => {
  if (num >= 1000000000) return (num / 1000000000).toFixed(1) + 'B';
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
  return num.toString();
};

const UserCard: React.FC<{ user: TikTokUserProfile }> = ({ user }) => (
  <motion.div
    layout
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-700 to-gray-800 p-4 sm:p-6 rounded-xl shadow-lg"
  >
    <div className="flex flex-col sm:flex-row items-center mb-4">
      <img src={user.profile_pic} alt={user.username} className="w-16 h-16 rounded-full mb-2 sm:mb-0 sm:mr-4 border-2 border-pink-500" />
      <div className="text-center sm:text-left overflow-hidden">
        <h4 className="font-bold text-lg sm:text-xl text-white truncate">{user.username}</h4>
        <p className="text-gray-300 text-xs sm:text-sm line-clamp-2">{user.bio}</p>
      </div>
    </div>
    <div className="grid grid-cols-3 gap-2 sm:gap-4 text-center">
      <div className="bg-gray-900 p-2 rounded-lg">
        <UserPlus className="w-4 h-4 sm:w-6 sm:h-6 text-blue-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Followers</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.followers.toLocaleString()}>{formatNumber(user.followers)}</p>
      </div>
      <div className="bg-gray-900 p-2 rounded-lg">
        <Heart className="w-4 h-4 sm:w-6 sm:h-6 text-red-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Likes</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.likes.toLocaleString()}>{formatNumber(user.likes)}</p>
      </div>
      <div className="bg-gray-900 p-2 rounded-lg">
        <Video className="w-4 h-4 sm:w-6 sm:h-6 text-green-400 mx-auto mb-1" />
        <p className="text-gray-300 text-xs">Posts</p>
        <p className="font-bold text-white text-xs sm:text-sm" title={user.posts.toLocaleString()}>{formatNumber(user.posts)}</p>
      </div>
    </div>
  </motion.div>
);

const UserSearch: React.FC = () => {
  const [username, setUsername] = useState('');
  const [searchResults, setSearchResults] = useState<TikTokUserSearchResults | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchCursor, setSearchCursor] = useState<string | null>(null);
  const callApi = useApi();

  const searchUser = useCallback(async (cursor?: string) => {
    setLoading(true);
    setError(null);
    try {
      const params: Record<string, string> = { username };
      if (cursor) {
        params.cursor = cursor;
      }
      const data: TikTokUserSearchResults = await callApi('/user/search', { params });
      setSearchResults(prevResults =>
        cursor && prevResults
          ? { ...data, users: [...prevResults.users, ...data.users] }
          : data
      );
      setSearchCursor(data.cursor);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [username, callApi]);

  const loadMoreSearchResults = () => {
    if (searchCursor) {
      searchUser(searchCursor);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-4 sm:p-6 lg:p-8 xl:p-10 2xl:p-12 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl max-w-7xl mx-auto"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 sm:mb-6 lg:mb-8 text-white flex items-center justify-center"
      >
        <Search className="mr-2 text-pink-500" /> TikTok User Search
      </motion.h2>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mb-4 sm:mb-6 lg:mb-8 flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4"
      >
        <div className="relative w-full sm:w-auto sm:flex-grow sm:max-w-md lg:max-w-lg">
          <input
            type="text"
            placeholder="Enter TikTok username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full bg-gray-700 p-3 pl-10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition duration-300"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => searchUser()}
          disabled={loading || !username.trim()}
          className="w-full sm:w-auto bg-gradient-to-r from-pink-500 to-purple-600 text-white px-4 sm:px-6 lg:px-8 py-2 lg:py-3 rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 disabled:opacity-50 flex items-center justify-center"
        >
          {loading ? <Loader className="animate-spin mr-2" /> : <Search className="mr-2" />}
          {loading ? 'Searching...' : 'Search User'}
        </motion.button>
      </motion.div>

      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-red-500 mb-4 text-center bg-red-100 border border-red-400 rounded-lg p-3"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>

      {searchResults && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 text-white">Search Results</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
            <AnimatePresence>
              {searchResults.users.map((user) => (
                <UserCard key={user.username} user={user} />
              ))}
            </AnimatePresence>
          </div>
          {searchResults.has_more > 0 && (
            <motion.div className="mt-6 lg:mt-8 flex justify-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={loadMoreSearchResults}
                disabled={loading}
                className="bg-blue-500 text-white px-6 py-3 rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 disabled:opacity-50 flex items-center"
              >
                {loading ? <Loader className="animate-spin mr-2" /> : null}
                {loading ? 'Loading...' : 'Load More Results'}
              </motion.button>
            </motion.div>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default UserSearch;