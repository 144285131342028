import { useAuth } from './AuthContext';

interface ApiOptions extends RequestInit {
  params?: Record<string, string>;
}

export const useApi = () => {
  const { token, logout } = useAuth();

  const callApi = async (endpoint: string, options: ApiOptions = {}, isResponseJson: boolean = true) => {
    const { params, ...fetchOptions } = options;
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`);
    
    if (params) {
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }

    const headers = new Headers(fetchOptions.headers);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    try {
      const response = await fetch(url.toString(), {
        ...fetchOptions,
        headers,
      });

      if (response.status === 401) {
        logout();
        throw new Error('Unauthorized');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}, details: ${await response.text()}`);
      }
      
      if (isResponseJson){
        return await response.json();
      }else{
        return await response.blob();
      }
        
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  };

  return callApi;
};