const fetchWithAuth = async (endpoint: string, options: RequestInit = {}) => {
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`);
    
    const headers = new Headers(options.headers);
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
  
    try {
      const response = await fetch(url.toString(), {
        ...options,
        headers,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  };
  
  export default fetchWithAuth;